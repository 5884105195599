<template>
	<h2 class="col-12 w-100 text-center ">
		{{ text }}
	</h2>
</template>

<script>
	export default {
		props: {
			text: String,
		},
	}
</script>
