<template>
	<div id="myModal" class="modal">
		<!-- Modal content -->
		<div class="modal-content">
			<span @click="close" class="close">&times;</span>
			<h3 class="text-center">Your bank account infomation</h3>
			<p class="text-center mt-4">
				We will use these bank details on letters to <br />
				your debtor in order for them to pay you directly
			</p>
			<div class="wrap">
				<form @submit.prevent="submitEvent(1, 1)" class="mt-5 w-75 mx-auto">
					<div class="row text-left">
						<div class="col-12">
							<div class="d-flex flex-column">
								<label for="name">Account Name</label>
								<input v-model="bankAccountDetails.accName" type="text" placeholder="Name " name="name" required />
							</div>
						</div>
						<div class="col-12 col-lg-6">
							<div class="d-flex flex-column">
								<label for="firstName">Account Number</label>
								<input
									v-model="bankAccountDetails.accNumber"
									type="number"
									minlength="6"
									maxlength="6"
									placeholder="12345678"
									name="dateMoneyOwed"
									required
								/>
							</div>
						</div>
						<div class="col-12 col-lg-6">
							<label>Bank sort code: XX-XX-XX</label>
							<div class="d-flex align-items-baseline">
								<input v-model="bankSortCode.sortCode" type="number" min="0" max="99" maxlength="2" size="2" placeholder="42" />
								-
								<input v-model="bankSortCode.sortCode2" type="number" min="0" max="99" maxlength="2" size="2" placeholder="42" />
								-
								<input v-model="bankSortCode.sortCode3" type="number" min="0" max="99" maxlength="2" size="2" placeholder="42" />
							</div>
						</div>
						<div class="col-xl-6 mx-auto">
							<button class="btn" type="submit">Submit Details</button>
						</div>
						<div v-if="errors.length" class="alert alert-danger" role="alert">
							<p v-for="x in errors" :key="x.id">{{ x }}</p>
						</div>
						<div v-if="msg.length" class="alert alert-success" role="alert">
							<p v-for="x in msg" :key="x.id">{{ x }}</p>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
	import { ref, reactive, computed } from 'vue'
	import { useStore } from 'vuex'
	import axios from 'axios'
	export default {
		setup() {
			const errors = ref([])
			const msg = ref([])
			const store = useStore()

			const contactType = computed(() => store.state.debtProcess.data.userDetails.contactType)

			const bankSortCode = reactive({
				sortCode: '',
				sortCode2: '',
				sortCode3: '',
			})
			const bankAccountDetails = reactive({
				accName: '',
				accNumber: '',
				sortcode: '',
			})

			let url = window.location.pathname
			let id = url.split('/')[url.split('/').length - 1]

			const close = () => {
				store.dispatch('debtProcess/bankAccModal')
			}

			const submitEvent = (whichStage, whichLetter) => {
				bankAccountDetails.sortcode = `${bankSortCode.sortCode}-${bankSortCode.sortCode2}-${bankSortCode.sortCode3}`

				axios({
					method: 'post',
					url: store.state.devUrl + 'api/Profile/AddBankDetails',
					headers: {
						'content-type': 'application/json',
						authorization: `Bearer ${localStorage.token}`,
					},
					data: bankAccountDetails,
				})
					.then(() => {
						if (contactType.value !== 'emails') {
							axios({
								method: 'get',
								url: store.state.devUrl + `api/Debt/DebtTemplate/${id}/${whichLetter}`,
								headers: {
									'content-type': 'application/json',
									authorization: `Bearer ${localStorage.token}`,
								},
							})
								.then(() => {
									// let fileURL = window.URL.createObjectURL(new Blob([response.data]))
									let fileLink = document.createElement('a')
									fileLink.href = `${store.state.devUrl}api/Debt/DebtTemplate/${id}/${whichLetter}`
									fileLink.setAttribute('download', 'DebtShelfLetter.docx')
									document.body.appendChild(fileLink)
									fileLink.click()

									axios({
										method: 'patch',
										url: store.state.devUrl + 'api/Debt/UpdateStageStatus',
										headers: {
											'content-type': 'application/json',
											authorization: `Bearer ${localStorage.token}`,
										},
										data: {
											debtId: id,
											stageId: whichStage,
											sectionId: whichLetter,
											progress: 2,
											isCompleted: false,
										},
									})
										.then(() => {
											store.dispatch('debtProcess/download', {
												whichStage,
												whichLetter,
											})
											msg.value.push('Account details submitted')
										})
										.then(() => {
											window.location.reload()
										})
										.catch(err => {
											console.log(err)
										})
								})
								.catch(err => {
									console.log('download err', err)
								})
						} else {
							msg.value.push('Account details submitted')
						}
					})
					.catch(err => console.log(err))
			}

			return {
				bankAccountDetails,
				contactType,
				close,
				bankSortCode,
				submitEvent,
				errors,
				msg,
			}
		},
	}
</script>

<style lang="scss" scoped>
	.modal {
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: rgb(0, 0, 0); /* Fallback color */
		background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
	}

	/* Modal Content */
	.modal-content {
		background-color: #fefefe;
		margin: auto;
		padding: 20px;
		border: 1px solid #888;
		width: 50%;
		border-radius: 8px;
	}

	/* The Close Button */
	.close {
		color: #aaaaaa;
		float: right;
		font-size: 28px;
		font-weight: bold;
		display: flex;
		justify-content: flex-end;
	}

	.close:hover,
	.close:focus {
		color: #000;
		text-decoration: none;
		cursor: pointer;
	}

	.wrap {
		background: #fff;
		border-radius: 10px;
		overflow: hidden;
		.btn {
			transition: all ease 0.3s;
		}
		.btn img {
			max-height: 15px;
		}
		.btn:hover img {
			filter: brightness(8.5);
		}
		.boxes {
			border: 1px solid;
			padding: 60px;
			border-radius: 30px;
			border-color: $darkBlue;
			transition: ease 0.3s all;
			&:hover {
				cursor: pointer;
				border-color: #fff;
				color: #fff;
				background: $darkBlue;
			}
		}
		.create {
			width: 200px;
			background: #30245d;
			color: #fff;
			position: absolute;
			right: 65px;
			bottom: -60px;
			z-index: 2;
			transition: linear all 0.3s;
		}
		.create:hover {
			transform: perspective(1px) scale(1.05);
		}
		input {
			outline: none;
			width: 100%;
			border-color: #d7d7d7;
			border-style: solid;
			border-radius: 5px;
			height: 40px;
			margin-bottom: 25px;
			padding: 5px 15px;
		}
		> .row:nth-child(odd) {
			background: #efefef;
		}
		.row {
			padding: 15px 0px;
		}
	}
	@media only screen and (max-width: 1024px) {
		.modal-content {
			width: 100%;
		}
	}
</style>
