<template>
	<div class="container" v-if="isLoaded">
		<h2 class="">Stage Overview</h2>
		<div class="seperator mb-5"></div>
		<div class="row">
			<div v-for="x in text" :key="x.id" class="col-12 col-lg-4 mobile-margin">
				<div class="box-wrapper h-100">
					<h5>Stage 0{{ x.stage }}</h5>
					<div class="seperator"></div>
					<div v-for="(i, index) in x.points" :key="i.id">
						<div v-if="check(i)" class="row m-0 mb-2 flex-nowrap">
							<input
							type="checkbox"
							class="checkbox"
							@click="noClick"
							name="checkbox"
							:id="'checkbox' + index"
							onclick="return false;"
							v-if="allData.stages[i.stage] && allData.stages[i.stage].section[i.section]"
							:checked="allData.stages[i.stage].section[i.section].sectionCompleted"
							/>
							<input
							type="checkbox"
							class="checkbox"
							@click="noClick"
							name="checkbox"
							:id="'checkbox' + index"
							onclick="return false;"
							v-if="!allData.stages[i.stage] || !allData.stages[i.stage].section[i.section]"
							/>
							<p>{{ i.text }}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
export default {
	setup() {
		const store = useStore()
		const allDebtProcessData = computed(() => store.state.debtProcess.data)
		const isLoaded = computed(() => store.state.debtProcess.isLoaded)
		const allData = computed(() => store.state.debtProcess.data)
		const isCommercial = computed(() => store.state.debtProcess.data.userDetails.isCommercial)
		const noClick = () => {
			return false
		}

		const check = x => {
			if (x.text === 'Interest Calculator' && x.show === false) {
				return false
			}
			return true
		}
		const text = ref([
			{
				stage: 1,
				points: [
					{
						text: 'Letter 01 - Gentle Reminder Letter',
						stage: 0,
						section: 0,
					},
					{
						text: 'Letter 02 - Notice of Debt recovery Action',
						stage: 0,
						section: 1,
					},
					{
						text: 'Letter 03 - LBA. (Letter Before Action)',
						stage: 0,
						section: 2,
					},
				],
			},
			{
				stage: 2,
				points: [
					{
						text: 'Court Claim',
						stage: 1,
						section: 1,
					},
					{
						text: 'How would you like to claim your debt?',
						stage: 1,
						section: 2,
					},
				],
			},
			{
				stage: 3,
				points: [
					{
						text: 'Have you received judgement?',
						stage: 2,
						section: 0,
					},
					{
						text: 'Upload paperwork',
						stage: 2,
						section: 1,
					},
					{
						text: 'Close debt',
						stage: 2,
						section: 2,
					},
				],
			}
		])

		return {
			allDebtProcessData,
			allData,
			isLoaded,
			isCommercial,
			noClick,
			check,
			text,
		}
	},
}
</script>

<style lang="scss" scoped>
.box-wrapper {
	box-shadow: 0px 3px 6px #00000029;
	background: #fff;
	border-radius: 10px;
	padding: 15px;
	p {
		margin: 0px;
	}
	.checkbox {
		margin: auto 0px;
		margin-right: 10px;
		margin-top: 5px;
	}
}
</style>
