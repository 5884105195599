<template>
	<div class="wrapper">
		<StageHeading>Stage 03</StageHeading>
		<div class="row mt-5 mb-5">
			<QuestionHeader text="Have you received judgement?" class="mb-5" />
			<div class="col-12 col-lg-6 mobile-margin">
				<BlueGradientBox @click="completed()">My case was successful and I've received payment</BlueGradientBox>
			</div>
			<div class="col-12 col-lg-6">
				<BlueGradientBox @click="showFeedbackFrom(3, 1)">Judgement awarded, not received payment?</BlueGradientBox>
			</div>
		</div>
		<div class="row appendDashboard4">
			<UploadModal dashboard=".appendDashboard4" :open="uploadModalLetter4" :uppyId="'four'" :isCreateADebt="false" :autoRefresh="true" :endpoint="`api/Debt/UploadDebtContent/${id}/3/2`"></UploadModal>
			<div class="col-12 mt-4">
				<Box v-if="nextStage[0].sectionCompleted">
					<template v-slot:header>
						<h5>Judgment Awarded</h5>
					</template>
					<template v-slot:description>
						<p>So you have been awarded Judgment but still not received what the court has said your owed? Debtshelf Experts can help in the recovery of your Judgement! Simply upload a copy of the Notice Of Judgment for the team to review. Once received our Experts will let you know the best route to getting paid what is rightfully owed.</p>
					</template>
					<template v-slot:buttons>
						<img class="d-flex mx-auto" src="/images/lackfords_logo.png" alt="" />
						<button @click="upload(4)" class="btn" :class="{ disabled: nextStage[0].value }">Upload Judgement</button>
						<button class="btn d-flex justify-content-center align-items-center" :class="{ complete: fullDebt.isCompleted }" @click="completed" v-if="nextStage[1] && nextStage[1].progress > 1">
							<i class="fas fa-clipboard-check mr-2"></i> Send to lackfords
							<span>?</span>
						</button>
						<p class="alert alert-success" v-if="nextStage[0].value">Your document has been upload and the debt has been completed. Please fill in the feedback form below:</p>
					</template>
				</Box>
			</div>
		</div>
		<transition name="slide-fade">
			<div class="row mt-5 mb-5" v-if="nextStage[0].value">
				<QuestionHeader text="How would you like to claim your debt?" class="mb-5" />
				<div class="col-12 col-lg-6 mobile-margin">
					<BlueGradientBox @click="showFeedbackFrom">
						<p class="mb-0">Debt completed, please close the debt</p>
					</BlueGradientBox>
				</div>
				<div class="col-12 col-lg-6">
					<BlueGradientBox>
						<p class="mb-0">Debt not completed, take me back to the dashboard</p>
					</BlueGradientBox>
				</div>
			</div>
		</transition>
		<transition name="slide-fade">
			<div v-if="fullDebt.isCompleted && !fullDebt.isFeedback">
				<QuestionHeader text="How did we do?" class="mt-5" />
				<div class="row">
					<div class="col-12 mt-4">
						<div class="box">
							<StarRating />
						</div>
					</div>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import axios from 'axios'
import StageHeading from '../sharedComponents/StageHeading'
import QuestionHeader from '../sharedComponents/QuestionHeader'
import BlueGradientBox from '../sharedComponents/BlueGradientBox'
import Box from '../sharedComponents/Box'
import StarRating from '../stage3/StarRating'
import UploadModal from '../sharedComponents/UploadModal'

export default {
	setup() {
		const isComplete = ref(false)
		const feedBackFrom = ref(false)
		const store = useStore()
		const fullDebt = computed(() => store.state.debtProcess.data)
		const debtProcess = computed(() => store.state.debtProcess.data.stages[2].section[0])

		const uploadModalLetter4 = computed(() => store.state.modal4)

		const nextStage = computed(() => store.state.debtProcess.data.stages[2].section)

		const upload = whichStage => {
			store.commit('toggleModal', whichStage)
		}

		let url = window.location.pathname
		let id = url.split('/')[url.split('/').length - 1]

		const completed = () => {
			axios({
				method: 'patch',
				url: store.state.devUrl + 'api/Debt/CompleteDebt',
				headers: {
					'content-type': 'application/json',
					authorization: `Bearer ${localStorage.token}`,
				},
				data: {
					id: id,
				},
			})
			.then(result => {
				if (result.status === 200) {
					axios({
						method: 'patch',
						url: store.state.devUrl + 'api/Debt/UpdateStageStatus',
						headers: {
							'content-type': 'application/json',
							authorization: `Bearer ${localStorage.token}`,
						},
						data: {
							debtId: id,
							stageId: 3,
							sectionId: 3,
							progress: 1,
							isCompleted: false,
						},
					})
					.then(() => {
						location.reload()
					})
					.catch(err => {
						console.log('err', err)
					})
				}
			})
			.catch(err => {
				console.log('err', err)
			})
		}

		const showFeedbackFrom = (whichStage, whichLetter) => {
			//     3          1
			axios({
				method: 'patch',
				url: store.state.devUrl + 'api/Debt/UpdateStageStatus',
				headers: {
					'content-type': 'application/json',
					authorization: `Bearer ${localStorage.token}`,
				},
				data: {
					debtId: id,
					stageId: whichStage,
					sectionId: whichLetter,
					progress: 3,
					isCompleted: true,
				},
			})
			.then(response => {
				store.dispatch('debtProcess/sectionCompleted', {
					whichStage,
					whichLetter,
					response,
				})
				// Build next section
				axios({
					method: 'patch',
					url: `${store.state.devUrl}api/Debt/UpdateStageStatus`,
					headers: {
						'content-type': 'application/json',
						authorization: `Bearer ${localStorage.token}`,
					},
					data: {
						debtId: id,
						stageId: whichStage,
						sectionId: whichLetter + 1,
						progress: 1,
						isCompleted: false,
					},
				})
				.then(response => {
					store.dispatch('debtProcess/sectionCompleted', {
						whichStage,
						whichLetter,
						response,
					})
					window.location.reload()
				})
				.catch(err => {
					console.log(err)
				})
			})
			.catch(err => {
				console.log(err)
			})
		}
		const leter2Complete = () => (isComplete.value = true)
		return {
			id,
			fullDebt,
			isComplete,
			leter2Complete,
			feedBackFrom,
			showFeedbackFrom,
			StageHeading,
			QuestionHeader,
			uploadModalLetter4,
			BlueGradientBox,
			UploadModal,
			nextStage,
			upload,
			completed,
			Box,
			StarRating,
			debtProcess,
		}
	},
}
</script>

<style lang="scss">
.blueBox p {
	transition: all 0.3s ease-out;
}
.blueBox:hover p {
	font-size: 20.6px;
}

.slide-fade-enter-active {
	transition: all 0.4s ease-out;
}

.slide-fade-leave-active {
	transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
	transform: translateY(20px);
	opacity: 0;
}
</style>
