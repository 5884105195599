<template>
	<div class="blueBox">
		<slot></slot>
	</div>
</template>

<style lang="scss">
	.blueBox {
		background: transparent
			linear-gradient(
				180deg,
				#30255e 0%,
				#2f2862 10%,
				#2d2c67 20%,
				#2b316d 30%,
				#293774 40%,
				#273a78 45%,
				#263c7b 50%,
				#234383 60%,
				#20498b 70%,
				#1c5094 80%,
				#19579d 90%,
				#145fa7 100%
			)
			0% 0% no-repeat padding-box;
		box-shadow: 0px 3px 6px #00000029;
		border-radius: 10px;
		min-height: 160px;
		color: #fff;
		font-size: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		padding: 0px 15px;
	}
	.blueBox:hover {
		cursor: pointer;
	}
</style>
