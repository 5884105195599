<template>
	<div>
		<StageHeading> Stage 02 </StageHeading>
		<div class="row">
			<div class="d-flex flex-wrap mt-5 mb-5 w-100 m-0-mobile" :class="{ disabled: section.length > 2 }">
				<div class="row w-100 m-0">
					<h2 class="mb-5 w-100 col-12 text-center">
						How would you like to claim your debt?
					</h2>
				</div>
				<div class="col-12 col-lg-6 mobile-margin">
					<a class="text-decoration-none" target="_blank" href="https://www.gov.uk/make-money-claim" rel="noopener noreferrer">
						<BlueGradientBox @click="updateStageStatus(2, 2)">Issue Court Claim</BlueGradientBox>
					</a>
				</div>
				<div class="col-12 col-lg-6" @click="triggerEmail()">
					<BlueGradientBox>Instruct Expert Panel Member</BlueGradientBox>
				</div>
			</div>
			<div class="col-12 mobile-margin">
				<Box>
					<template v-slot:header>
						<h5>Court Claim</h5>
					</template>
					<template v-slot:description>
						<p>
							Money Claim Online is the Gov.UK website that links you to the court system in order to make a claim. Before a Money
							Claim is made it is expected that you have taken reasonable steps to recover the alleged debt. Once your claim is
							registered Money Claim Online will contact your Debtor and guide you through the Judgment process. Just click on the
							link and follow the steps. If you feel you would like help feel free to consult one of our Experts!
						</p>
					</template>
					<template v-slot:buttons>
						<a href="https://www.gov.uk/make-money-claim" target="_blank">
							<button class="btn d-flex justify-content-center align-items-center">
								<i class="fas fa-link mr-2"></i>Goverment Link
							</button>
						</a>
						<button
						class="btn d-flex justify-content-center align-items-center"
						@click="completed(2, 2, 3)"
						:class="{ complete: section[0].sectionCompleted }"
						>
						<i class="fas fa-clipboard-check mr-2"></i> Completed
						<div>?</div>
					</button>
				</template>
			</Box>
		</div>
	</div>

	<!-- user feedback pop up -->
	<div class="closeDebtPopup" v-if="showFeedbackPopup">
		<div class="inner" v-if="isCompleted">
			<h3>Your case has now been forwarded</h3>
			<p>An email with your details have been sent to our experts and will be in touch shortly</p>
			<button class="btn mb-0" @click="completed(2, 2, 3)">Return to debt</button>
		</div>
		<div v-else>
			<div id="loading-wrapper">
				<div id="loading-text">LOADING</div>
				<div id="loading-content"></div>
			</div>
		</div>
	</div>
</div>
</template>

<script>
import StageHeading from '../sharedComponents/StageHeading'
import QuestionHeader from '../sharedComponents/QuestionHeader'
import BlueGradientBox from '../sharedComponents/BlueGradientBox'
import axios from 'axios'
import Box from '../sharedComponents/Box'
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'

export default {
	setup() {
		let percentage = ref('')
		let interestAmount = ref(0)
		const showFeedbackPopup = ref(false)
		const store = useStore()
		const debtAmount = computed(() => store.state.debtProcess.data.userDetails)
		const isCommercial = computed(() => store.state.debtProcess.data.userDetails.isCommercial)
		const debtProcess = computed(() => store.state.debtProcess.data)
		const currentStageAllowed = computed(() => store.state.debtProcess.currentStage)
		const finalStage = () => {
			store.commit('debtProcess/finalStage')
		}

		const section = computed(() => store.state.debtProcess.data.stages[1].section)

		const isLoaded = computed(() => store.state.debtProcess.isLoaded)

		const isCompleted = ref(false)

		const triggerEmail = () => {
			// email experts@debtshelf.co.uk user info
			axios({
				method: 'post',
				url: store.state.devUrl + 'api/Debt/ExpertPanelEmail',
				headers: {
					'content-type': 'application/json',
					authorization: `Bearer ${localStorage.token}`,
				},
				data: {
					id: debtProcess.value.id,
				},
			})
			.then(() => {
				// Open popup to tell user what Happened
				showFeedbackPopup.value = true
				isCompleted.value = true
			})
			.catch(err => {
				console.log(err)
			})
		}
		const showInterest = () => {
			let base = store.state.debtProcess.data.userDetails.amount.substring(1)
			let cal = ((percentage.value / 100) * Number(base)).toFixed(2)

			interestAmount.value = cal
		}

		const updateStageStatus = (whichStage, whichLetter) => {
			axios({
				method: 'patch',
				url: store.state.devUrl + 'api/Debt/UpdateStageStatus',
				headers: {
					'content-type': 'application/json',
					authorization: `Bearer ${localStorage.token}`,
				},
				data: {
					debtId: debtProcess.value.id,
					stageId: whichStage,
					sectionId: whichLetter,
					progress: 3,
					isCompleted: false,
				},
			})
			.then(() => {
				store.dispatch('debtProcess/sectionCompleted', {
					whichStage,
					whichLetter,
				})
			})
			.catch(err => {
				console.log(err)
			})
		}

		const completed = (whichStage, whichLetter, selected) => {
			axios({
				method: 'patch',
				url: store.state.devUrl + 'api/Debt/UpdateStageStatus',
				headers: {
					'content-type': 'application/json',
					authorization: `Bearer ${localStorage.token}`,
				},
				data: {
					debtId: debtProcess.value.id,
					stageId: whichStage,
					sectionId: whichLetter,
					progress: selected,
					isCompleted: true,
				},
			})
			.then(() => {
				if (whichLetter !== 2) {
					axios({
						method: 'patch',
						url: `${store.state.devUrl}api/Debt/UpdateStageStatus`,
						headers: {
							'content-type': 'application/json',
							authorization: `Bearer ${localStorage.token}`,
						},
						data: {
							debtId: debtProcess.value.id,
							stageId: whichStage,
							sectionId: whichLetter + 1,
							progress: 1,
							isCompleted: false,
						},
					})
					.then(response => {
						store.dispatch('debtProcess/sectionCompleted', {
							whichStage,
							whichLetter,
							response,
						})
						window.location.reload()
					})
					.catch(err => {
						console.log(err)
					})
				} else {
					axios({
						method: 'patch',
						url: `${store.state.devUrl}api/Debt/CompleteStage/${debtProcess.value.id}/${whichStage}`,
						headers: {
							'content-type': 'application/json',
							authorization: `Bearer ${localStorage.token}`,
						},
					})
					.then(response => {
						store.dispatch('debtProcess/stageCompleted', {
							whichStage,
							response,
						})
						axios({
							method: 'patch',
							url: `${store.state.devUrl}api/Debt/UpdateStageStatus`,
							headers: {
								'content-type': 'application/json',
								authorization: `Bearer ${localStorage.token}`,
							},
							data: {
								debtId: debtProcess.value.id,
								stageId: whichStage + 1,
								sectionId: 1,
								progress: 1,
								isCompleted: false,
							},
						})
						.then(response => {
							store.dispatch('debtProcess/startStage', {
								whichStage,
								response,
							})
							location.reload()
						})
						.catch(err => {
							console.log(err)
						})
					})
					.catch(err => {
						console.log(err)
					})
				}
			})
			.then(() => {})
			.catch(err => {
				console.log(err)
			})
		}
		onMounted(() => {
			const newDate = new Date()
			const currentDate = newDate.toLocaleDateString('en-US')
			function parseDate(str) {
				var mdy = str.split('/')
				return new Date(mdy[2], mdy[0] - 1, mdy[1])
			}
			function datediff(first, second) {
				return Math.round((second - first) / (1000 * 60 * 60 * 24))
			}
			const days = datediff(
				parseDate(store.state.debtProcess.data.userDetails.dueDate.substring(0, 10)),
				parseDate(currentDate)
			)
			const dailyIntrest = (store.state.debtProcess.data.userDetails.amount.substring(1) * 0.0875) / 365
			const intrestAmount = dailyIntrest * days
			interestAmount.value = intrestAmount.toFixed(2)
		})

		return {
			StageHeading,
			isLoaded,
			section,
			Box,
			currentStageAllowed,
			completed,
			triggerEmail,
			updateStageStatus,
			finalStage,
			QuestionHeader,
			showInterest,
			debtAmount,
			BlueGradientBox,
			percentage,
			interestAmount,
			isCommercial,
			debtProcess,
			showFeedbackPopup,
			isCompleted,
		}
	},
}
</script>

<style lang="scss">
@media only screen and (max-width: 768px) {
	.m-0-mobile {
		margin: 0px;
	}
}
</style>

<style lang="scss" scoped>
/* The Close Button */
.closeDebtPopup .inner {
	height: auto;
	max-height: 25vh;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.close {
	color: #aaa;
	float: right;
	font-size: 28px;
	font-weight: bold;
}

.close:hover,
.close:focus {
	color: black;
	text-decoration: none;
	cursor: pointer;
}
</style>
