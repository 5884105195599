`<template>
	<div>
		<p>Our legally compliant letter templates are pre filled with your debt details and can be sent by email or printed to send by post</p>
		<BankDetailsModal v-if="showBankDetailsPopUp" />
		<div class="row">
			<div class="col-12 col-lg-6 mobile-margin appendDashboard">
				<!-- LETTER 1 -->
				<UploadModal
				dashboard=".appendDashboard"
				:open="uploadModalLetter1"
				:uppyId="'one'"
				:endpoint="`api/Debt/UploadDebtContent/${debtProcess.id}/1/1`"
				:autoRefresh="true"
				:isCreateADebt="false"
				></UploadModal>
				<Box>
					<template v-slot:header>
						<h5>Letter 01 - Gentle Reminder Letter</h5>
					</template>
					<template v-slot:description>
						<p>Sometimes a gentle reminder is all that’s required, this template is designed to make contact less formal way, Perfect for good customers that may just need a gentle nudge!</p>
					</template>
					<template v-slot:buttons>
						<button class="btn" v-if="section[0].progress > 0 && !section[0].sectionCompleted" @click="preview(1, 1)">
							<i class="fas fa-eye"></i> view letter
						</button>
						<button class="btn" v-if="section[0].progress > 0 && !section[0].sectionCompleted" @click="download(1, 1)">
							<i class="fas fa-upload mr-2"></i>Print Letter
						</button>
						<button class="btn" v-if="section[0].progress >= 2 && !section[0].sectionCompleted" @click="completed(1, 1)">
							<i class="fas fa-file-alt mr-2"></i>Letter sent
						</button>
						<button class="btn complete" v-if="section[0].sectionCompleted && section[0].progress < 4">
							<i class="fas fa-file-alt mr-2"></i>Next Stage
						</button>
						<button class="btn" v-if="section[0].progress > 0 && section[0].progress < 3" @click="email(1, 1)">
							<i class="fas fa-file-alt mr-2"></i>Email Letter
						</button>
						<button class="btn complete" v-if="section[0].progress >= 3 && section[0].progress < 4">
							<i class="fas fa-file-alt mr-2"></i>Letter Emailed
						</button>
						<button class="btn" v-if="section[0].progress > 0 && section[0].progress < 2 && !section[0].sectionCompleted" @click="skip(1, 1)">
							<i class="fas fa-file-alt mr-2"></i>Skip
						</button>
						<button class="btn complete" v-if="section[0].progress > 4 && section[0].sectionCompleted">
							<i class="fas fa-file-alt mr-2"></i>Skiped
						</button>
					</template>
				</Box>
			</div>
			<div class="col-12 col-lg-6 appendDashboard2">
				<!-- LETTER 2 -->
				<UploadModal
				dashboard=".appendDashboard2"
				:open="uploadModalLetter2"
				:uppyId="'two'"
				:endpoint="`api/Debt/UploadDebtContent/${debtProcess.id}/1/2`"
				:autoRefresh="true"
				:isCreateADebt="false"
				></UploadModal>
				<Box :class="{ disabled: section.length < 2 }">
					<template v-slot:header>
						<h5>Letter 02- Notice of Debt recovery Action</h5>
					</template>
					<template v-slot:description>
						<p>When a more formal approach is required, our Debt Recovery Letter puts your Debtor on Notice that you are formally requesting payment of a delinquent account.</p>
					</template>
					<template v-slot:buttons v-if="section[1]">
						<button class="btn" v-if="section[1].progress > 0 && !section[1].sectionCompleted" @click="preview(1, 2)">
							<i class="fas fa-eye"></i> view letter
						</button>
						<button class="btn" v-if="section[1].progress > 0 && !section[1].sectionCompleted" @click="download(1, 2)">
							<i class="fas fa-upload mr-2"></i>Print Letter
						</button>
						<button class="btn" v-if="section[1].progress >= 2 && !section[1].sectionCompleted" @click="completed(1, 2)">
							<i class="fas fa-file-alt mr-2"></i>Letter sent
						</button>
						<button class="btn complete" v-if="section[1].sectionCompleted">
							<i class="fas fa-file-alt mr-2"></i>Next Stage
						</button>
						<button class="btn" v-if="section[1].progress > 0 && section[1].progress < 3" @click="email(1, 2)">
							<i class="fas fa-file-alt mr-2"></i>Email Letter
						</button>
						<button class="btn complete" v-if="section[1].progress >= 3">
							<i class="fas fa-file-alt mr-2"></i>Letter Emailed
						</button>
						<button class="btn" v-if="section[1].progress > 0 && !section[1].sectionCompleted && section[0].progress < 4" @click="skip(1, 2)">
							<i class="fas fa-file-alt mr-2"></i>Skip
						</button>
						<button class="btn complete" v-if="section[1].progress > 4 && section[1].sectionCompleted">
							<i class="fas fa-file-alt mr-2"></i>Skiped
						</button>
					</template>
				</Box>
			</div>
			<div class="col-12 mt-4 uploadModalLetter3">
				<!-- LETTER 3 -->
				<UploadModal
				dashboard=".appendDashboard3"
				:open="uploadModalLetter3"
				:uppyId="'three'"
				:endpoint="`api/Debt/UploadDebtContent/${debtProcess.id}/1/3`"
				:autoRefresh="true"
				:isCreateADebt="false"
				></UploadModal>
				<Box :class="{ disabled: section.length < 3 }">
					<template v-slot:header>
						<h5>Letter 03 - LBA. (Letter Before Action) <span v-if="isCommercial">(LBA Commercial)</span><span v-else>(LBA Non-Commercial)</span></h5>
					</template>
					<template v-slot:description>
						<p>The Letter Before Action or LBA is a legal requirement before commencing legal action, our LBA is designed to make it very clear that further steps will be taken if payment is not received within 7 Days.<br>We always recommend that this notice is sent in a recordable manner, we would suggest using the Royal Mail Signed for service.</p>
					</template>
					<template v-slot:buttons v-if="section[2]">
						<button class="btn" v-if="section[2].progress > 0 && !section[2].sectionCompleted" @click="preview(1, 3)">
							<i class="fas fa-eye"></i> view letter
						</button>
						<button class="btn" v-if="section[2].progress > 0 && !section[2].sectionCompleted" @click="download(1, 3)">
							<i class="fas fa-upload mr-2"></i>Print Letter
						</button>
						<button class="btn" v-if="section[2].progress >= 2 && !section[2].sectionCompleted" @click="completed(1, 3)">
							<i class="fas fa-file-alt mr-2"></i>Letter sent
						</button>
						<button class="btn complete" v-if="section[2].sectionCompleted">
							<i class="fas fa-file-alt mr-2"></i>Next Stage
						</button>
						<button class="btn" v-if="section[2].progress > 0 && section[2].progress < 3" @click="email(1, 3)">
							<i class="fas fa-file-alt mr-2"></i>Email Letter
						</button>
						<button class="btn complete" v-if="section[2].progress >= 3">
							<i class="fas fa-file-alt mr-2"></i>Letter Emailed
						</button>
					</template>
				</Box>
			</div>
		</div>
	</div>
</template>

<script>
import StageHeading from '../sharedComponents/StageHeading'
import BankDetailsModal from '../sharedComponents/BankDetailsModal'
import Box from '../sharedComponents/Box'
import { ref, computed } from 'vue'
import axios from 'axios'
import { useStore, mapGetters } from 'vuex'
import UploadModal from '../sharedComponents/UploadModal'

export default {
	computed: {
		...mapGetters('debtProcess', ['hasLoaded', 'allStagesData', 'bankAccModalGetter']),
	},
	setup() {
		const store = useStore()

		// Data
		const currentDebt = ref(null)
		const currentProgress = ref(0)

		// Computed
		const uploadModalLetter1 = computed(() => store.state.modal1)
		const uploadModalLetter2 = computed(() => store.state.modal2)
		const uploadModalLetter3 = computed(() => store.state.modal3)
		const urlEndPoint = computed(() => store.state.urlEndPoint)
		const debtProcess = computed(() => store.state.debtProcess.data)
		const currentStageAllowed = computed(() => store.state.debtProcess.currentStage)
		const hasBankDetail = computed(() => store.state.user.data.hasBankDetail)
		const showBankDetailsPopUp = computed(() => store.state.debtProcess.bankAccModal)
		const section = computed(() => store.state.debtProcess.data.stages[0].section)
		const isCommercial = computed(() => store.state.debtProcess.data.userDetails.isCommercial)
		const contactType = computed(() => store.state.debtProcess.data.userDetails.contactType)

		// Methods
		const email = (whichStage, whichLetter) => {
			if (whichStage === 1 && whichLetter === 1 && !hasBankDetail.value) {
				store.dispatch('debtProcess/bankAccModal')
				return false
			}

			axios({
				method: 'patch',
				url: store.state.devUrl + `api/Debt/EmailDebtSection/${debtProcess.value.id}/${whichLetter}`,
				headers: {
					'content-type': 'application/json',
					authorization: `Bearer ${localStorage.token}`,
				},
			})
			.then(() => {
				axios({
					method: 'patch',
					url: store.state.devUrl + 'api/Debt/UpdateStageStatus',
					headers: {
						'content-type': 'application/json',
						authorization: `Bearer ${localStorage.token}`,
					},
					data: {
						debtId: debtProcess.value.id,
						stageId: whichStage,
						sectionId: whichLetter,
						progress: 3,
						isCompleted: false,
					},
				})
				.then(() => {
					store.dispatch('debtProcess/email', { whichStage, whichLetter })
				})
				.catch(err => {
					console.log(err)
				})
			})
			.catch(err => {
				console.log(err)
			})
		}
		const download = (whichStage, whichLetter) => {
			if (whichStage === 1 && whichLetter === 1 && !hasBankDetail.value) {
				store.dispatch('debtProcess/bankAccModal')
				return false
			}

			axios({
				method: 'get',
				url: store.state.devUrl + `api/Debt/DebtTemplate/${debtProcess.value.id}/${whichLetter}`,
				headers: {
					'content-type': 'application/json',
					authorization: `Bearer ${localStorage.token}`,
				},
			})
			.then(() => {
				let fileLink = document.createElement('a')
				fileLink.href = `${store.state.devUrl}api/Debt/DebtTemplate/${debtProcess.value.id}/${whichLetter}`
				fileLink.setAttribute('download', 'DebtShelfLetter.docx')
				document.body.appendChild(fileLink)
				fileLink.click()
			})
			.catch(err => {
				console.log('download err', err)
			})

			axios({
				method: 'patch',
				url: store.state.devUrl + 'api/Debt/UpdateStageStatus',
				headers: {
					'content-type': 'application/json',
					authorization: `Bearer ${localStorage.token}`,
				},
				data: {
					debtId: debtProcess.value.id,
					stageId: whichStage,
					sectionId: whichLetter,
					progress: 2,
					isCompleted: false,
				},
			})
			.then(() => {
				store.dispatch('debtProcess/download', { whichStage, whichLetter })
			})
			.catch(err => {
				console.log(err)
			})
		}

		const preview = (whichStage, whichLetter) => {
			window
			.open(
				`https://docs.google.com/gview?url=${store.state.devUrl}api/Debt/DebtTemplate/${debtProcess.value.id}/${whichLetter}&embedded=true`,
				'blank'
			)
			.focus()
		}

		const skip = (whichStage, whichLetter) => {
			if (whichStage === 1 && whichLetter === 1 && !hasBankDetail.value) {
				store.dispatch('debtProcess/bankAccModal')
				return false
			}
			axios({
				method: 'patch',
				url: store.state.devUrl + 'api/Debt/UpdateStageStatus',
				headers: {
					'content-type': 'application/json',
					authorization: `Bearer ${localStorage.token}`,
				},
				data: {
					debtId: debtProcess.value.id,
					stageId: whichStage,
					sectionId: whichLetter,
					progress: 5,
					isCompleted: true,
				},
			})
			.then(() => {
				store.dispatch('debtProcess/skip', { whichStage, whichLetter })

				axios({
					method: 'patch',
					url: `${store.state.devUrl}api/Debt/UpdateStageStatus`,
					headers: {
						'content-type': 'application/json',
						authorization: `Bearer ${localStorage.token}`,
					},
					data: {
						debtId: debtProcess.value.id,
						stageId: whichStage,
						sectionId: whichLetter + 1,
						progress: 1,
						isCompleted: false,
					},
				})
				.then(response => {
					store
					.dispatch('debtProcess/sectionCompleted', {
						whichStage,
						whichLetter,
						response,
					})
					.then(() => window.location.reload())
				})
				.catch(err => {
					console.log(err)
				})
			})
			.catch(err => {
				console.log(err)
			})
		}

		console.log(debtProcess.value.stages[0].section[0].progress)

		const completed = (whichStage, whichLetter) => {
			if (whichStage === 1 && whichLetter === 1 && !hasBankDetail.value) {
				store.dispatch('debtProcess/bankAccModal')
				return false
			}

			if (debtProcess.value.stages[whichStage - 1].section[whichLetter - 1].progress < 3) {
				currentProgress.value = 2
			} else {
				currentProgress.value = 3
			}

			axios({
				method: 'patch',
				url: store.state.devUrl + 'api/Debt/UpdateStageStatus',
				headers: {
					'content-type': 'application/json',
					authorization: `Bearer ${localStorage.token}`,
				},
				data: {
					debtId: debtProcess.value.id,
					stageId: whichStage,
					sectionId: whichLetter,
					progress: currentProgress.value,
					isCompleted: true,
				},
			})
			.then(() => {
				store.dispatch('debtProcess/completed', { whichStage, whichLetter })

				if (whichLetter !== 3) {
					// Build next stage
					axios({
						method: 'patch',
						url: `${store.state.devUrl}api/Debt/UpdateStageStatus`,
						headers: {
							'content-type': 'application/json',
							authorization: `Bearer ${localStorage.token}`,
						},
						data: {
							debtId: debtProcess.value.id,
							stageId: whichStage,
							sectionId: whichLetter + 1,
							progress: 1,
							isCompleted: false,
						},
					})
					.then(response => {
						store
						.dispatch('debtProcess/sectionCompleted', {
							whichStage,
							whichLetter,
							response,
						})
						.then(() => window.location.reload())
					})
					.catch(err => {
						console.log(err)
					})
				} else {
					axios({
						method: 'patch',
						url: `${store.state.devUrl}api/Debt/CompleteStage/${debtProcess.value.id}/${whichStage}`,
						headers: {
							'content-type': 'application/json',
							authorization: `Bearer ${localStorage.token}`,
						},
					})
					.then(response => {
						store.dispatch('debtProcess/stageCompleted', {
							whichStage,
							response,
						})
						axios({
							method: 'patch',
							url: `${store.state.devUrl}api/Debt/UpdateStageStatus`,
							headers: {
								'content-type': 'application/json',
								authorization: `Bearer ${localStorage.token}`,
							},
							data: {
								debtId: debtProcess.value.id,
								stageId: whichStage + 1,
								sectionId: 1,
								progress: 1,
								isCompleted: false,
							},
						})
						.then(response => {
							store.dispatch('debtProcess/startStage', {
								whichStage,
								response,
							})
							location.reload()
						})
						.catch(err => {
							console.log(err)
						})
					})
					.catch(err => {
						console.log(err)
					})
				}
			})
			.catch(err => {
				console.log(err)
			})
		}

		return {
			axios,
			BankDetailsModal,
			StageHeading,
			Box,
			currentStageAllowed,
			showBankDetailsPopUp,
			UploadModal,
			uploadModalLetter1,
			uploadModalLetter2,
			uploadModalLetter3,
			hasBankDetail,
			urlEndPoint,
			debtProcess,
			contactType,
			section,
			isCommercial,
			currentDebt,
			download,
			email,
			preview,
			completed,
			skip,
		}
	},
}
</script>
`
