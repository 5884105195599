<template>
	<div class="home container" v-if="loaded">
		<SetMeta :API="false" :Title="debtData.userDetails.name + ' Debt'" />
		<div class="closeDebt" v-if="!debtData.isCompleted" @click="openCompletePopup()">
			<i class="fas fa-check"></i>
			<span>Close debt</span>
		</div>
		<div class="closeDebtPopup" v-if="completeDebtPopup">
			<div class="inner">
				<p>Are you sure you want to close this debt?</p>
				<button class="btn w-auto pl-3 pr-3" @click="closeCompletePopup()">Keep debt open</button>
				<button class="btn w-auto pl-3 pr-3 ml-3 highlighted" @click="completeDebt()">Close debt</button>
			</div>
		</div>
		<div class="container">
			<div class="headingContent">
				<h2>Debt Info Overview</h2>
			</div>
			<div class="seperator mb-5"></div>
			<div class="row">
				<div class="col-12 col-lg-6">
					<div class="box">
						<div class="row">
							<div class="col-12">
								<h5 class="mb-0">Debtor Info</h5>
								<div class="seperator"></div>
								<div class="row">
									<div class="col-12 col-lg-6">
										<ul>
											<li>
												Name of the debtor:
												<strong>{{ debtData.userDetails.name }}</strong>
											</li>
											<li>
												Address of the debtor:
												<strong>{{ debtData.userDetails.address.lineOne }}</strong>
											</li>
										</ul>
									</div>
									<div class="col-12 col-lg-6 ">
										<ul>
											<li>
												Postcode:
												<strong>{{ debtData.userDetails.address.postal }}</strong>
											</li>
											<li>
												Country:
												<strong>{{ debtData.userDetails.address.country }}</strong>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-12 col-lg-6">
					<div class="box">
						<div class="row">
							<div class="col-12">
								<h5 class="mb-0">Invoice Owed</h5>
								<div class="seperator"></div>
								<div class="row">
									<div class="col-12 col-lg-6">
										<ul>
											<li>Amount to be paid: <strong>{{ debtData.userDetails.amount }}</strong></li>
											<li>Interest: <strong>{{ debtData.userDetails.intrest }}</strong></li>
										</ul>
									</div>
									<div class="col-12 col-lg-6 ">
										<ul>
											<li>The debt was due to be paid on: <strong class="mt-2 d-block">{{ debtData.userDetails.dueDate.split(' ')[0] }}</strong></li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Headers />
		<div class="col-12 col-lg-4 mx-auto mt-5 text-center">
			<button v-if="debtData.stage === 0" @click="startDebt()" class="btn mx-auto">
				Click To Start This Debt
			</button>
		</div>
		<div>
			<div class="col-12 mt-5">
				<StageOne v-if="debtData.stage" class="mb-5 mt-5" />
				<StageTwo v-if="debtData.stage > 1" class="mb-5 mt-5" />
					<StageThree v-if="debtData.stages.length > 2" class="mb-5 mt-5" />
					</div>
				</div>
			</div>
			<div style="height: 80vh;" v-if="!loaded">
				<div id="loading-wrapper">
					<div id="loading-text">LOADING</div>
					<div id="loading-content"></div>
				</div>
			</div>
		</template>

		<script>
		import SetMeta from '@/components/_Meta/SetData'
		import { ref, computed } from 'vue'
		import { useStore } from 'vuex'
		import { useRouter } from 'vue-router'
		import axios from 'axios'
		import Headers from '@/components/debt/Headers.vue'
		import StageOne from '@/components/debt/stage1/StageOne.vue'
		import StageTwo from '@/components/debt/stage2/StageTwo.vue'
		import StageThree from '@/components/debt/stage3/StageThree.vue'

		var currentDate = new Date()

		export default {
			name: 'Home',
			components: {
				Headers,
				StageOne,
				StageTwo,
				StageThree,
			},
			setup() {
				const store = useStore()
				const debtData = computed(() => store.state.debtProcess.data)
				const router = useRouter()

				// Data
				const loaded = ref(false)
				const currentDebt = ref(null)
				const completeDebtPopup = ref(false)
				const date = ref({
					day: '',
					month: '',
					year: '',
					yyyymmdd: '',
					stageOneCompleted: '',
					stageOneAllowed: '',
					stageTwoCompleted: '',
					stageTwoAllowed: ''
				})

				// Methods
				const startDebt = () => {
					const body = {
						debtId: id,
						stageId: 1,
						sectionId: 1,
						progress: 1,
						isCompleted: false,
					}
					axios({
						method: 'patch',
						url: store.state.devUrl + 'api/Debt/UpdateStageStatus',
						headers: {
							'content-type': 'application/json',
							authorization: `Bearer ${localStorage.token}`,
						},
						data: body,
					})
					.then(response => {
						currentDebt.value = store.state.debtProcess.data
						currentDebt.value.stage = 1
						currentDebt.value.stages.push({
							stageID: 1,
							startDate: response.data.stageStartDate,
							completeDate: response.data.stageCompleteDate,
							nextStageAllowDate: response.data.stageNextStageAllowDate,
							section: [
								{
									id: 1,
									progress: 1,
									sectionCompleted: false,
									startedOn: response.data.sectionStartedOn,
									completedOn: response.data.sectionCompleteOn,
								},
							],
						})
						store.commit('debtProcess/getAllStagesData', currentDebt.value)
					})
					.catch(err => {
						console.log(err)
					})
				}
				const completeDebt = () => {
					axios({
						method: 'patch',
						url: store.state.devUrl + 'api/Debt/CompleteDebt',
						headers: {
							'content-type': 'application/json',
							authorization: `Bearer ${localStorage.token}`,
						},
						data: {
							id: debtData.value.id,
						},
					})
					.then(() => {
						location.reload()
					})
					.catch(err => {
						console.log('err', err)
					})
				}
				const openCompletePopup = () => {
					completeDebtPopup.value = true
				}
				const closeCompletePopup = () => {
					completeDebtPopup.value = false
				}

				// Created
				store.dispatch('showNav')
				let url = window.location.pathname
				let id = url.split('/')[url.split('/').length - 1]

				axios({
					method: 'get',
					url: store.state.devUrl + 'api/Debt/Fetch/' + id,
					headers: {
						'content-type': 'application/json',
						authorization: `Bearer ${localStorage.token}`,
					},
				})
				.then(response => {
					store.commit('debtProcess/getAllStagesData', response.data)

					if(currentDate.getDate() < 10){
						date.value.day = '0' + currentDate.getDate()
					} else {
						date.value.day = currentDate.getDate()
					}
					if((currentDate.getMonth()+1) < 10){
						date.value.month = '0' + (currentDate.getMonth()+1)
					} else {
						date.value.month = (currentDate.getMonth()+1)
					}
					date.value.year = currentDate.getFullYear()
					date.value.yyyymmdd = date.value.year + date.value.month + date.value.day

					if(response.data.stages.length > 0 && response.data.stages[0].section.length > 2){
						date.value.stageOneCompleted = response.data.stages[0].section[1].completedOn.slice(6, 10) + response.data.stages[0].section[1].completedOn.slice(0, 2) + response.data.stages[0].section[1].completedOn.slice(3, 5)
						date.value.stageOneAllowed = parseInt(date.value.stageOneCompleted) + 7
						if(date.value.yyyymmdd >= date.value.stageOneAllowed){
							store.commit('debtProcess/currentStageCommit')

							date.value.stageTwoCompleted = response.data.stages[0].completeDate.slice(6, 10) + response.data.stages[0].completeDate.slice(0, 2) + response.data.stages[0].completeDate.slice(3, 5)
							date.value.stageTwoAllowed = parseInt(date.value.stageOneCompleted) + 7
							if(date.value.yyyymmdd >= date.value.stageTwoAllowed){
								store.commit('debtProcess/currentStageCommit')
							}
						}
					}
					console.log(date.value)
					loaded.value = true
				})
				.catch(() => {
					router.push('/')
				})

				return {
					currentDate,
					SetMeta,
					Headers,
					StageOne,
					StageTwo,
					StageThree,
					store,
					debtData,
					loaded,
					startDebt,
					completeDebt,
					completeDebtPopup,
					openCompletePopup,
					closeCompletePopup,
				}
			},
		}
		</script>

		<style lang="scss">
		// import uppy
		body {
			background: #f2f2f2;
		}

		span {
			display: inline-flex;
		}

		.closeDebt{
			position: fixed;
			bottom: 90px;
			right: 14px;
			width: 45px;
			height: 45px;
			background-color: $darkBlue;
			border-radius: 50%;
			cursor: pointer;
			transition: 0.6s ease all;
			z-index: 9;
			overflow: hidden;

			i {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				margin: auto;
				width: fit-content;
				height: fit-content;
				color: #fff;
				transition: 0.6s ease all;
			}
			span {
				position: absolute;
				top: 0;
				bottom: 0;
				margin: auto 0;
				right: -100px;
				width: fit-content;
				height: fit-content;
				color: #fff;
				white-space: nowrap;
				transition: 0.6s ease all;
			}
		}
		.closeDebt:hover {
			height: 45px;
			width: 150px;
			border-radius: 20px;

			i {
				margin: auto 0;
				left: 20px;
			}
			span {
				right: 20px;
			}
		}
		.closeDebtPopup {
			position: fixed;
			left: 0;
			top: 0;
			width: 100vw;
			height: 100vh;
			padding-top: 100px;
			background-color: rgba(0, 0, 0, 0.5);
			z-index: 12;

			.inner {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				width: 30%;
				height: fit-content;
				max-height: 20vh;
				margin: auto;
				background-color: #fff;
				border-radius: 20px;
				padding: 30px;
				text-align: center;
			}
		}

		.box {
			background: #fff;
			padding: 15px;
			box-shadow: 0px 3px 6px #00000029;
			border-radius: 10px;
			margin-bottom: 50px;
			ul {
				padding: 0px;
				margin-top: 15px;
				li {
					list-style: none;
					margin-bottom: 15px;
				}
			}
		}
		.headingContent {
			position: relative;
			width: 100%;

			.btn {
				position: absolute;
				top: 0;
				right: 0;
			}
		}
		.home {
			padding: 60px 0px;
			h5 {
				margin-bottom: 15px;
			}
			.btn {
				border-radius: 14px;
				color: $darkBlue;
				border: 1px solid $darkBlue;
				margin-bottom: 15px;
				width: 100%;
			}
			.btn.complete {
				background: #28a745;
				border: #28a745;
				color: #fff;
			}
			.btn.highlighted {
				background-color: $darkBlue;
				color: #fff;
			}
			.btn.complete:focus {
				box-shadow: none;
			}
			.seperator {
				width: 100%;
				background: $darkBlue;
				height: 2px;
				margin: 15px 0px;
			}
		}

		@media only screen and (min-width: 990px) {
			.home {
				position: relative;
			}
			// ! Deprecated, might use later
			// .home:after {
			// 	content: '';
			// 	position: absolute;
			// 	width: 2px;
			// 	background-color: #30245d;
			// 	top: 0;
			// 	bottom: 0;
			// 	left: 0;
			// 	margin-left: -3px;
			// 	padding-top: 10px;
			// 	margin-top: 30px;
			// 	margin-bottom: 30px;
			// }
		}

		@media only screen and (max-width: 990px) {
			.home {
				padding-left: 0px;
				position: relative;
			}
			.mobile-margin {
				margin-bottom: 30px;
			}
			.headingContent .btn {
				position: relative;
			}
		}
		</style>
