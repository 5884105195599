<template>
	<div class="box">
		<div class="row">
			<div class="col-12">
				<slot name="header"></slot>
				<div class="row">
					<div class="col-12 col-lg-8">
						<div class="seperator"></div>
						<slot name="description"></slot>
					</div>
					<div class="col-12 col-lg-4 ">
						<div class="flex-column">
							<slot name="buttons"></slot>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
	.box {
		background: #fff;
		padding: 15px;
		box-shadow: 0px 3px 6px #00000029;
		border-radius: 10px;

		button {
			width: 100%;
			border: 1px solid $darkBlue;
			margin-bottom: 15px;
		}
		.seperator {
			margin-top: 0px;
		}
	}
</style>
